import { ReactNode } from "react";
import styled from "styled-components";

interface Props{
    children: ReactNode,
    style: any,
}

const AssayCard:  React.FC<Props> = ({children, style}) => {
    return (
        <CardContainer style={style}>{children}</CardContainer>
    )
}

const CardContainer = styled.div`
    background: #fff;
    min-height: 20px;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 5px;
`

export default AssayCard;
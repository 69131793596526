import LoginForm from "../../components/auth/LoginForm";
import HomeLayout from "../../components/layouts/HomeLayout";

const LoginPage = () => {
    return (
        <HomeLayout>
            <LoginForm/>
        </HomeLayout>
    )
}

export default LoginPage;
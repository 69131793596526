import React from 'react';
import './App.css';
import { BrowserRouter as Router , Route, Routes, Link } from "react-router-dom";
import {
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  HomePage,
  EmployeesPage, 
  DepartmentsPage, 
  JobTitlesPage,
  AllOKRS,
  BusinessInformationPage,
  ModulesPage,
  BillingPage,
} from './pages';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage/>}/>
        <Route path="/sign-up" element={<RegisterPage/>}/>
        <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
        <Route path="/dashboard" element={<HomePage/>}/>
        <Route path="/employees" element={<EmployeesPage/>}/>
        <Route path="/departments" element={<DepartmentsPage/>}/>
        <Route path="/job-titles" element={<JobTitlesPage/>}/>

        <Route path="/all-okrs" element={<AllOKRS/>}/>

        <Route path="/job-titles" element={<JobTitlesPage/>}/>

        <Route path="/business-infomation" element={<BusinessInformationPage/>}/>
        <Route path="/modules" element={<ModulesPage/>}/>
        <Route path="/billing" element={<BillingPage/>}/>

      </Routes>
    </Router>
  );
}

export default App;

import styled from "styled-components";
import Logo from "../../assets/logo_white.png";
import home_bg from '../../assets/home_img.png';
import {ReactNode} from "react";

interface Props {
    children?: ReactNode | ReactNode[]
}

const HomeLayout = (props: Props) => {
    return(
        <Wrapper>
            <Left>
                <Homebackground >
                    <div className="logoImageWrapper">
                       <img src={Logo} alt='Assay'/>
                    </div>
                    <div className="introTextWrapper">
                       <p>Unlock your team's potential, drive <br/> performance and deliver results</p>
                    </div>
                </Homebackground>
            </Left>
            <Right>
                <PageContent>
                   {props.children}
                </PageContent>
            </Right>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: #F4F5FA;
    height: auto;
    width: 100%;
    display: flex;
`

const Left = styled.div`
    width: 638px;
    min-height:900px;
`
const Homebackground = styled.div`
   min-height:900px;
   width: 638px;
   object-fit: cover;
   position: relative; 
   background: url(${home_bg}) no-repeat;
   background-position:0px -120px;
   .logoImageWrapper{
       position: absolute;
       top: 3%;
       left: 16%;
       z-index:10; 
       img{
        width: 170px;
        height: 42px;
        object-fit: contain;
       }
   }
   .introTextWrapper{
        bottom: -70px;
        bottom: 30px;
        left:  70px;
        color: #808080;
        font-size: 16px;
        line-height:1.5;
        position: absolute;
    }
`
const Right = styled.div`
    flex: 1;
    padding: 0px 20px 0px 0px;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
`

const PageContent = styled.div`
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export default HomeLayout;
import {useState, useContext, useEffect, useCallback} from "react";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import styled from "styled-components";
import { getSelf } from "../../services/User";
import PrimaryButton from "../core/PrimaryButton";


import makeRequest from '../../services/fetch-request';
import { setLocalStorage }  from '../../services/local-storage';

const LoginForm = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleSubmitUserLogin = (values:any) => { 
        let endpoint = 'auth/jwt/create';                                       
        makeRequest({url: endpoint, method: 'POST', data: values}).then(([status, response]) => {
            if(status === 201 ){               
                setLocalStorage('user', response.data);
                navigate('/home')
            } else {             
                console.log(response.message)
            }                                                                   
        })     
    } 

    return(
        <Card>
            <h1 className="mt-5 pt-5 text-center login-title">Login</h1>
            <p>Kindly fill in your details to login</p>
            <div className="form-container ">
                <form onSubmit={handleSubmit(handleSubmitUserLogin)}>
                    <div className="form-group">
                        <input type="text" 
                            className="form-control py-2" 
                            id="email" 
                            placeholder="Enter email"
                            aria-invalid={errors.email ? "true" : "false"}
                            {...register('email', { required: true})}
                        />
                        {errors.email && (
                            <span role="alert" className="form-alert">Check your email</span>
                        )}
                    </div>
                    <div className="form-group mt-3">
                        <input 
                        type="password" 
                        className="form-control py-2" 
                        id="password" 
                        placeholder="Password"
                        aria-invalid={errors.password ? "true" : "false"}
                        {...register('password', { required: true})}/>
                        {errors.password && (
                            <span role="alert" className="form-alert">Check your password</span>
                        )}
                    </div>
                    <div className="form-group pt-4">
                        <PrimaryButton 
                        type="submit"
                        title = "Login"
                        />
                    </div>
                    <p className="mt-4 mb-4 text-center"><a href="/forgot-password" className="forgotPass">Forgot Password? </a></p>
                    <p className="mt-3 text-center">Don't have an account? <a href="/sign-up" className="forgotPass">Sign up</a></p>
                </form>
            </div>
        </Card>
    )
}

const Card = styled.div`
    width: 384px;
    margin-left:10%;
    height: 400px;
    display: flex;
    flex-direction: column;
    p{
        font-size:14px;
        color: rgba(58, 53, 65, 0.6);
        line-height: 20px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.15px;
    }
    input, select {
        width: 384px;
        height: 56px;
        font-size: 16px;
    }
    .login-title{
        color:#003049;
        font-size:24px;
        margin-top:60px;
        margin-bottom: 20px;
    }
    .form-group{
        text-align: left
    }
    .login-form-wrapper img{
        width: 150px;
        height: 50px;
        object-fit: contain;
    }
    .form-group{
        flex:1;
    }
    .form-container{
        paddong-top: 0px;
    }
    .bigger{
        font-size:15px;
        margin-bottom:7px;
    }
    a.forgotPass{
        color: #FFB400;
        text-decoration: none;
    }`

export default LoginForm;
import PageHeader from "../../../components/core/PageHeader";
import styled from "styled-components";
import MainLayout from "../../../components/layouts/MainLayout";
import AssayCard from "../../../components/core/AssayCard";
import Breadcrumbs  from "../../../components/core/Breadcrump";

const BusinessInformationPage = () => {
    return (
        <MainLayout showSideMenu={true}>
            <PageHeader title="Business Information"></PageHeader>
        </MainLayout>
    )
}

export default BusinessInformationPage;
import {useState, useContext, useEffect, useCallback} from "react";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import styled from "styled-components";
import PrimaryButton from "../core/PrimaryButton";

import makeRequest from '../../services/fetch-request';
import { setLocalStorage }  from '../../services/local-storage';

const RegisterForm = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleSubmitUserLogin = (values:any) => { 
        let endpoint = 'auth/users/';  
        try{                                   
        makeRequest({url: endpoint, method: 'POST', data: values}).then(([status, response]) => {
            if(status === 201 ){               
                setLocalStorage('user', response.data);
                navigate('/home')
            } else {             
                console.log(response.message)
            }                                                                   
        }) 
    }catch(e: any){
        console.log(e.message)
    } 
    } 

    return(
        <Card>
            <h1 className="mt-1 pt-5 login-title">Try Assay for free!</h1>
            <p>Get 1 month of Assay! Fill out this form to create your <br/> trial account.</p>
            <div className="form-container ">
                <form onSubmit={handleSubmit(handleSubmitUserLogin)}>
               
                <div className="form-group">
                        <input type="text" 
                            className="form-control py-2" 
                            id="first_name" 
                            placeholder="First name"
                            aria-invalid={errors.email ? "true" : "false"}
                            {...register('first_name', { required: true})}
                        />
                        {errors.first_name && (
                            <span role="alert" className="form-alert">Check your first name</span>
                        )}
                    </div>
                    <div className="form-group">
                        <input type="text" 
                            className="form-control py-2" 
                            id="last_name" 
                            placeholder="Last name"
                            aria-invalid={errors.last_name ? "true" : "false"}
                            {...register('last_name', { required: true})}
                        />
                        {errors.email && (
                            <span role="alert" className="form-alert">Check your last name</span>
                        )}
                    </div>

                    <div className="form-group">
                        <input type="text" 
                            className="form-control py-2" 
                            id="username" 
                            placeholder="Select a username"
                            aria-invalid={errors.username ? "true" : "false"}
                            {...register('username', { required: true})}
                        />
                        {errors.first_name && (
                            <span role="alert" className="form-alert">Check your username</span>
                        )}
                    </div>

                    <div className="form-group">
                        <input type="text" 
                            className="form-control py-2" 
                            id="email" 
                            placeholder="Enter email"
                            aria-invalid={errors.email ? "true" : "false"}
                            {...register('email', { required: true})}
                        />
                        {errors.email && (
                            <span role="alert" className="form-alert">Check your email</span>
                        )}
                    </div>
                   
                    <div className="form-group mt-3">
                        <input 
                        type="password" 
                        className="form-control py-2" 
                        id="password" 
                        placeholder="Password"
                        aria-invalid={errors.password ? "true" : "false"}
                        {...register('password', { required: true})}/>
                        {errors.password && (
                            <span role="alert" className="form-alert">Check your password</span>
                        )}
                    </div>

                    <div className="form-group mt-3 mb-3">
                        <input 
                        type="password" 
                        className="form-control py-2" 
                        id="re_password" 
                        placeholder="Confirm Password"
                        aria-invalid={errors.password ? "true" : "false"}
                        {...register('re_password', { required: true})}/>
                        {errors.password && (
                            <span role="alert" className="form-alert">Please confirm your password</span>
                        )}
                    </div>
                        <PrimaryButton 
                        type="submit"
                        title = "CREATE MY TRIAL"
                        />
                </form>
                <p className="mt-5 pt-2 text-center">Don't have an account? <a href="/" className="forgotPass">Sign in</a></p>
            </div>
        </Card>
    )
}

const Card = styled.div`
    width: 384px;
    margin-left:10%;
    height: 400px;
    display: flex;
    flex-direction: column;
    p{
        font-size:14px;
        color: rgba(58, 53, 65, 0.6);
        line-height: 20px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.15px;
    }
    input, select {
        width: 384px;
        height: 56px;
        font-size: 16px;
    }
    .login-title{
        color:#003049;
        font-size:24px;
        margin-bottom: 20px;
    }
    .form-group{
        text-align: left
    }
    .login-form-wrapper img{
        width: 150px;
        height: 50px;
        object-fit: contain;
    }
    .form-group{
        flex:1;
    }
    .form-container{
        paddong-top: 0px;
    }
    .bigger{
        font-size:15px;
        margin-bottom:7px;
    }
    a.forgotPass{
        color: #FFB400;
        text-decoration: none;
    }`

export default RegisterForm;
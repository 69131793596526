import PageHeader from "../../../components/core/PageHeader";
import styled from "styled-components";
import MainLayout from "../../../components/layouts/MainLayout";
import AssayCard from "../../../components/core/AssayCard";
import Breadcrumbs  from "../../../components/core/Breadcrump";

const BillingPage = () => {
    return (
        <MainLayout showSideMenu={true}>

            <PageHeader title="Billing"></PageHeader>


        </MainLayout>
    )
}


export default BillingPage;
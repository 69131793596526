import styled from "styled-components";
import SideMenu from "./SideMenu";

import React, {ReactNode} from "react";
import Header from "./Header";

interface Props {
    children?: ReactNode | ReactNode[]
    showSideMenu: boolean
}

const MainLayout = (props: Props) => {
    return(
        <Wrapper>
            <Left>
                <SideMenu/>
            </Left>
            <Right>
                <Header/>
                <PageContent>
                   {props.children}
                </PageContent>
            </Right>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: #F4F5FA;
    height: 100vh;
    width: 100vw;
    display: flex;
`

const Left = styled.div`
    width: 260px;
    height:0px;
    overflow-y: auto;
`

const Right = styled.div`
    flex: 1;
    padding: 0px 20px 0px 60px;
    position: relative;
`

const PageContent = styled.div`
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
`

export default MainLayout;
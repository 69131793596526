import styled from "styled-components";
import PageHeader from "../../components/core/PageHeader";
import MainLayout from "../../components/layouts/MainLayout";

const HomePage = () => {
    return (
        <MainLayout showSideMenu={true}>
            <PageHeader title="Dashboard"></PageHeader>
        </MainLayout>
    )
}

const Home = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
}
    `

export default HomePage;
import RegisterForm from "../../components/auth/RegisterForm";
import HomeLayout from "../../components/layouts/HomeLayout";

const RegisterPage = () => {
    return (
        <HomeLayout>
            <RegisterForm/>
        </HomeLayout>
    )
}

export default RegisterPage;
import { ReactNode } from "react";
import styled from "styled-components";

interface Props{
    children: ReactNode,
}

const EmployeeBreadCrump:  React.FC<Props> = ({children}) => {
    return (
        <List>
            {children}
        </List>
    )
}

const List = styled.ul`
    color: #003049;
    font-size: 24px;
    display: block;
    margin: 0;
    padding: 0;
    li{
       float: left;
       list-style: none;
       margin-right: 5px;
       display:block;
       padding: 10px 20px;
       color: #8A8D93;
       font-size: 14px;
       text-transform: uppercase; 
       &:hover, &.active{
          background-color: rgba(133, 212, 255, 0.12);
       }
    }
`

export default EmployeeBreadCrump;
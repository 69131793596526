import styled from "styled-components";

interface Props{
    title: string,
    onPress:  () => void,
    icon: any,
    showArror: boolean,
    active: boolean
};

const MainMenuItem: React.FC<Props> = ({title, onPress, icon, showArror, active}) => {
     return (
        <Item onClick={onPress} className={active ? "itemActive" : ""}>
            <div className="title-wrapper">
                {icon}
                <p>{title}</p>
            </div>
            <div className="arrow-icon-wrapper">
                {showArror && (
                  <i className="fa fa-angle-down"></i>
                )}
            </div>
        </Item>
    )
};

const Item = styled.div`
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0px;
    color: rgba(58, 53, 65, 0.87);
    &:hover, &.itemActive{
        background-image: linear-gradient(to right, #001D2D, #003049, #005079);
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        color: #fff;
    }
    .title-wrapper{
        display: flex;
        flex-direction: row;
        i{
            font-size: 15px;
            margin-right: 15px;
            margin-top: 5px;
        }
        p{
            margin:0px;
            padding:0px;
            font-size: 16px;
        }
    }
`;


export default MainMenuItem;
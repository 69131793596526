import styled from "styled-components";
import Avatar from 'react-avatar';
import Drawer from 'react-modern-drawer'
import { useState } from "react";

const Header = (props: any) => {

    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    return(
        <HeaderWrapper>
            <div className="header-search-wrapper">
                <i className="search-icon fa fa-search"/>
                <div className="user-profile-wrapper">
                    <div className="notification-wrapper">
                        <i className="fa fa-bell-o"></i>
                    </div>
                    <Avatar onClick={toggleDrawer} color={'#188754'} round={true} name="Evans Wanyama" size="35" />
                </div>
            </div>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                enableOverlay = {false}
                size = {500}
            >
                <div 
                onClick = {toggleDrawer}>
                    <p>User Details</p>
                </div>
            </Drawer>
        </HeaderWrapper>
    )
}

const HeaderWrapper = styled.div`
    display:flex;
    flex:1;
    .search-icon,.setting-icon {
         font-size: 20px;
         top: 9px;
     }
     .search-icon{
        left:13px;
     }
     .setting-icon{
        right:12px;
        cursor:pointer;
     }
    .header-search-wrapper{
        border: 1px solid #ccc;
        border-top: 0px;
        display: flex;
        flex:1;
        background-color: #fff;
        padding:18px 25px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
        position: relative;
    }
    .user-profile-wrapper{
        position: absolute;
        right: 22px;
        top: 8px;
        display: flex;
        flex-direction: row;
    }
    .notification-wrapper{
        width: auto;
    }
    .notification-wrapper i{
        font-size: 20px;
        margin: 7px 15px 0px 0px;
    }
    `
export default Header;
import { Accordion } from "@mui/material";
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import styled from "styled-components";
import { ReactNode, useId } from "react";

interface Props{
    title: string,
    icon: any,
    showArror: boolean,
    active: boolean,
    children: ReactNode,
};

const MenuItemWithAccordion: React.FC<Props> = ({title, icon, active, children}) => {
    const id = useId().replace(/:/g,'');
     return (
        <Item>
            <div className="accordion" id={`#${id}`}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`flush-heading-${id}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-${id}`} aria-expanded="false" aria-controls={`flush-${id}`}>
                        {icon} {title}
                        </button>
                    </h2>
                    <div id={`flush-${id}`} className="accordion-collapse collapse" data-bs-parent={`#${id}`} aria-labelledby={`flush-heading-${id}`}>
                        <div className="accordion-body">
                           {children}
                        </div>
                    </div>
                </div>
            </div>
        </Item>
    )
};

const Item = styled.div`
    width: 100%;
    cursor: pointer;
    margin: 5px 10px 0px 0px;
    .accordion{
        background-color: #F4F5FA;
    }
    .accordion-body {
        padding:0px;
        background-color: #F4F5FA;
    }
    .accordion-item {
        background-color: #F4F5FA;
        border: none;
    }
    .accordion-button{
        background-color: #F4F5FA;
    }
    .accordion-button:not(.collapsed), .active {
        color: rgba(58, 53, 65, 0.87);
        background: rgba(58, 53, 65, 0.04);
        border-radius: 0px 50px 50px 0px;
        box-shadow: none;
        padding: 10px 20px;
    }
    .accordion-button::after {
        background-size: 11px 11px;
    }
    .accordion-header{
        background-color:  #F4F5FA;
    }
`;


export default MenuItemWithAccordion;
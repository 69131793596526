import ForgotPasswordForm from "../../components/auth/ForgotPassword";
import HomeLayout from "../../components/layouts/HomeLayout";

const ForgotPassword = () => {
    return (
        <HomeLayout>
            <ForgotPasswordForm/>
        </HomeLayout>
    )
}

export default ForgotPassword;
import styled from "styled-components";

export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
    title: string,
}

function PrimaryButton(props: ButtonProps) {
    const {title, ...rest} = props;
    return (
        <Button {...rest}>
            {title}
        </Button>
    )
}

const Button = styled.button`
    background-color: #003049;
    width: 100%;
    padding: 15px;
    color: #fff;
    text-align: center;
    border: 1px solid #003049;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
`


export default PrimaryButton;
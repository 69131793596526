import styled from "styled-components";

interface Props{
    title: string,
}

const MainMenuHeader: React.FC<Props> = ({title}) => {
    return (
        <Item>
            <hr/>
            <span>{title}</span>
        </Item>
    )
}

const Item = styled.div`
     height: 30px;;
     position: relative;
     display: flex;
     align-items: center;
     color: rgba(58, 53, 65, 0.38);
     margin-top: 25px;
     margin-bottom: 7px;
     hr{
        z-index: 0;
        color: rgba(58, 53, 65, 0.38);
        top: 50%;
        width: 100%;

     }
     span{
        position: absolute;
        margin-top: -2px;
        margin:-1px 20px 0px 20px;
        padding:0px 5px;
        text-transform: uppercase;
        background-color: #F4F5FA;
        font-size: 12px;
     }
`


export default MainMenuHeader;
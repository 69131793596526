import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ThreeSixtyOutlinedIcon from '@mui/icons-material/ThreeSixtyOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';

import MainMenuHeader from "../menu/MainMenuHeader";
import MainMenuItem from "../menu/MainMenuItem";
import logo from "../../assets/logo.png";
import { ANALYTICS, BILLING, BUSINESS_INFORMATION, DASHBOARD, DEPARTMENTS, EMPLOYEES, JOBTITLES, MODULES, OKRS } from "../../constants/menuItems";
import MenuItemWithAccordion from "../menu/MenuItemWithAccordion";

const SideMenu = (props: any) => {
    
    const location = useLocation();
    const navigate = useNavigate();
    const [currentRoute, setCurrentRoute] = useState((location.pathname.substring(1)));

    const navigateToPage = (page: any) => {
        navigate(page);
    }

    return(
        <Wrapper>

             <LogoWrapper>
                <a href="/"><img className="imglogo" src={logo} alt=" Logo"/></a>
             </LogoWrapper>

             <MenuWrapper>

                <MenuItemWithAccordion title="Home"  icon={<HomeOutlinedIcon sx={{ fontSize: 23, marginRight: "9px", }}/>} showArror={true} active = {currentRoute === DASHBOARD}>
                    <MainMenuItem title="Dashboard" onPress={() => navigateToPage("/"+ DASHBOARD)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === DASHBOARD}/>
                    <MainMenuItem title="Analytics" onPress={() => navigateToPage("/"+ ANALYTICS)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === ANALYTICS}/>
                </MenuItemWithAccordion>

                <MainMenuHeader title = "Performance Management"/>

                <MenuItemWithAccordion title="OKRs"  icon={<GpsFixedOutlinedIcon sx={{ fontSize: 23, marginRight: "9px", }}/>} showArror={true} active = {currentRoute === OKRS}>
                    <MainMenuItem title="All OKRs" onPress={() => navigateToPage("/all-okrs")}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === "all-okrs"}/>
                    <MainMenuItem title="Jambo's OKRs " onPress={() => navigateToPage("/all-okrs")}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {false}/>
                    <MainMenuItem title="Department OKRs " onPress={() => navigateToPage("/all-okrs")}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {false}/>
                    <MainMenuItem title="My OKRs " onPress={() => navigateToPage("/all-okrs")}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {false}/>
                </MenuItemWithAccordion>

                <MenuItemWithAccordion title="Balanced Scorecard"  icon={<ContentPasteIcon sx={{ fontSize: 23, marginRight: "9px", }}/>} showArror={true} active = {currentRoute === DASHBOARD}>
                    <MainMenuItem title="Dashboard" onPress={() => navigateToPage("/"+ DASHBOARD)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === DASHBOARD}/>
                    <MainMenuItem title="Analytics" onPress={() => navigateToPage("/"+ ANALYTICS)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === ANALYTICS}/>
                </MenuItemWithAccordion>

                <MenuItemWithAccordion title="Check-in"  icon={<RestoreOutlinedIcon sx={{ fontSize: 23, marginRight: "9px", }}/>} showArror={true} active = {currentRoute === DASHBOARD}>
                    <MainMenuItem title="Dashboard" onPress={() => navigateToPage("/"+ DASHBOARD)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === DASHBOARD}/>
                    <MainMenuItem title="Analytics" onPress={() => navigateToPage("/"+ ANALYTICS)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === ANALYTICS}/>
                </MenuItemWithAccordion>

                <MenuItemWithAccordion title="360 Feedback"  icon={<ThreeSixtyOutlinedIcon sx={{ fontSize: 23, marginRight: "9px", }}/>} showArror={true} active = {currentRoute === DASHBOARD}>
                    <MainMenuItem title="Dashboard" onPress={() => navigateToPage("/"+ DASHBOARD)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === DASHBOARD}/>
                    <MainMenuItem title="Analytics" onPress={() => navigateToPage("/"+ ANALYTICS)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === ANALYTICS}/>
                </MenuItemWithAccordion>

                <MenuItemWithAccordion title="One-on-Ones"  icon={<ForumOutlinedIcon sx={{ fontSize: 23, marginRight: "9px", }}/>} showArror={true} active = {currentRoute === DASHBOARD}>
                    <MainMenuItem title="Dashboard" onPress={() => navigateToPage("/"+ DASHBOARD)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === DASHBOARD}/>
                    <MainMenuItem title="Analytics" onPress={() => navigateToPage("/"+ ANALYTICS)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === ANALYTICS}/>
                </MenuItemWithAccordion>

                <MenuItemWithAccordion title="Reviews"  icon={<ReviewsOutlinedIcon sx={{ fontSize: 23, marginRight: "9px", }}/>} showArror={true} active = {currentRoute === DASHBOARD}>
                    <MainMenuItem title="Dashboard" onPress={() => navigateToPage("/"+ DASHBOARD)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === DASHBOARD}/>
                    <MainMenuItem title="Analytics" onPress={() => navigateToPage("/"+ ANALYTICS)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === ANALYTICS}/>
                </MenuItemWithAccordion>

                <MainMenuHeader title = "Settings"/>

                <MainMenuItem title="Employees" onPress={() => navigateToPage("/" + EMPLOYEES)}   icon={<PeopleOutlineOutlinedIcon sx={{ marginRight: "15px",}}/>} showArror={false} active = {currentRoute === EMPLOYEES}/>
                <MainMenuItem title="Departments" onPress={() => navigateToPage("/" + DEPARTMENTS)}   icon={<AccountTreeOutlinedIcon sx={{ marginRight: "15px",}}/>}  showArror={false} active = {currentRoute === DEPARTMENTS}/>
                <MainMenuItem title="Job Titles" onPress={() => navigateToPage("/" + JOBTITLES)}   icon={<BadgeOutlinedIcon sx={{ marginRight: "15px",}}/>}  showArror={false} active = {currentRoute === JOBTITLES}/>

                <MenuItemWithAccordion title="Organization"  icon={<DomainOutlinedIcon sx={{ fontSize: 23, marginRight: "9px", }}/>} showArror={true} active = {currentRoute === DASHBOARD}>
                    <MainMenuItem title="Business Information" onPress={() => navigateToPage("/business-infomation")}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === BUSINESS_INFORMATION}/>
                    <MainMenuItem title="Modules" onPress={() => navigateToPage("/"+ MODULES)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === MODULES}/>
                    <MainMenuItem title="Billing" onPress={() => navigateToPage("/"+ BILLING)}   icon={<i className="fa fa-circle-thin"/>} showArror={true} active = {currentRoute === BILLING}/>
                </MenuItemWithAccordion>

             </MenuWrapper>
             
        </Wrapper>
    )
}


const Wrapper = styled.div`
    height: 100%;
    width:260px;
    position:fixed;
    z-index: 10; 
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; 
`

const LogoWrapper = styled.div`
    margin: 2% 0 12% 10%;
    flex: 0.2;
    .imglogo{
        width: auto;
        height:auto;
        margin-top:2px;
    }
`
const MenuWrapper = styled.div`
  flex: 6;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 15px;
`

export default SideMenu;
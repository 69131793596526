export const DASHBOARD = 'dashboard';
export const ANALYTICS = 'analytics';
export const OKRS = 'OKRS';

export const EMPLOYEES = 'employees';
export const DEPARTMENTS = 'departments';
export const JOBTITLES = 'job-titles';


export const BUSINESS_INFORMATION = 'Business Information';
export const MODULES = 'modules';
export const BILLING = 'billing';


import PageHeader from "../../components/core/PageHeader";
import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout";
import AssayCard from "../../components/core/AssayCard";
import Breadcrumbs  from "../../components/core/Breadcrump";

const AllOKRs = () => {
    return (
        <MainLayout showSideMenu={true}>
            
            <PageHeader title="Objectives and Key Result Areas"></PageHeader>

            <Breadcrumbs>
                <li className="active">Individual OKRs</li>
                <li>Jambo’s OKRs</li>
                <li>Department OKRs</li>
            </Breadcrumbs>

            <AssayCard style={{ marginTop: 25, padding: "10px"}}>
            </AssayCard>

            <AssayCard style={{ marginTop: 25, minHeight: 300 }}>
            </AssayCard>

        </MainLayout>
    )
}

export default AllOKRs;
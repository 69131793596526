import PageHeader from "../../components/core/PageHeader";
import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout";
import AssayCard from "../../components/core/AssayCard";

const JobTitlesPage = () => {
    return (
        <MainLayout showSideMenu={true}>
            <PageHeader title="Job Titles"></PageHeader>
            <AssayCard style={{ marginTop: 25, padding: "10px"}}>

            </AssayCard>

            <AssayCard style={{ marginTop: 25, minHeight: 300 }}>
            </AssayCard>
        </MainLayout>
    )
}

const Home = styled.div`
}
`

export default JobTitlesPage;
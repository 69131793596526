import styled from "styled-components";

interface Props{
    title: string
}

const PageHeader:  React.FC<Props> = ({title}) => {
    return (
        <HeaderTxt>{title}</HeaderTxt>
    )
}

const HeaderTxt = styled.h1`
    color: #003049;
    font-size: 24px;
    margin: 20px 0px;
`

export default PageHeader;
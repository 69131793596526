import PageHeader from "../../components/core/PageHeader";
import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout";
import AssayCard from "../../components/core/AssayCard";
import Breadcrumbs  from "../../components/core/Breadcrump";

const EmployeesPage = () => {
    return (
        <MainLayout showSideMenu={true}>

            <PageHeader title="Active Employees"></PageHeader>

            <Breadcrumbs>
                <li className="active">All(2)</li>
                <li>Task Users (2)</li>
                <li>Read Only (1)</li>
                <li>Super Users (2)</li>
            </Breadcrumbs>

            <AssayCard style={{ marginTop: 25, padding: "10px"}}>
            </AssayCard>

            <AssayCard style={{ marginTop: 25, minHeight: 300 }}>
            </AssayCard>

        </MainLayout>
    )
}


export default EmployeesPage;